import React from "react";
import { useSelector } from "react-redux";
const Username = () => {
    const username = useSelector(state => state.login.user.name);
    return (
        <>
            {username}
        </>
    )
};

export default Username;
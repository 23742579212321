import { createSlice } from "@reduxjs/toolkit";

const requestSlice = createSlice({
    name: 'request',
    initialState: { requests: []},
    reducers: {
        setRequest(state, action) {
            state.requests = action.payload;
        },
        updateValues(state, action) {
           
            let oldData = {...action.payload.row};
            
            if(action.payload.column.dataField === 'agentId') {
                oldData['agentId'] = parseInt(action.payload.newValue);
            } else {
                oldData[action.payload.column.dataField] = action.payload.newValue;
            }
            
          
            state.requests = state.requests.map(i => {
                if(i.id === oldData.id) {
                    return oldData;
                }

                return i;
            })
        }  

    },
});

export const requestActions = requestSlice.actions;

export default requestSlice;

export const BASE_URL = 'https://admin.goldtreeastro.com';
export const API_URL = 'https://api.goldtreeastro.com';

export const LOGOUT_URL = API_URL + '/logout';
export const REGISTER_URL = API_URL + '/signup';
export const REQUESTS_URL = API_URL + '/urequests';
export const ADMIN_REQUEST_URL = API_URL + '/admin-urequests';
export const AGENT_URL = API_URL + '/agents';
export const AGENT_ALL_URL = API_URL + '/adminAgents';
export const USER_DETAILS_URL = API_URL + '/user-details';
export const CALL_REQUEST_URL = API_URL + '/agents/urequests';
export const CALL_URL = API_URL + '/agentCall';
export const REMEDY_URL = API_URL + '/remedy-lists';
export const REMEDIES_URL = API_URL + '/remedies';
export const REMEDY_RESPONSE_URL = API_URL + '/remedy-responses';
export const HISTORY_URL = API_URL + '/call-histories';

export const REQUEST_AGENT_WITH_ID = (id) => {
    return `${API_URL}/agents/${id}`;
}

export const REQUEST_REMEDY_WITH_ID = (id) => {
    return `${API_URL}/remedy-lists/${id}`;
}

export const REQUEST_URL_WITH_ID = (id) => {
    return `${API_URL}/urequests/${id}`;
}

export const REQUEST_HISTORY_URL = (id) => {
    return `${API_URL}/urequests/${id}/call-histories`;
}

export const REQUEST_FEEDBACK_URL = (id) => {
    return `${API_URL}/urequests/${id}/feedbacks`;
}

export const REQUEST_REMEDIES_URL = (id) => {
    return `${API_URL}/urequests/${id}/remedies`;
}

export const REQUEST_BACKUP_URL = (id) => {
    return `${API_URL}/urequests/${id}/backups`;
}

export const FLAG_URL = (id) => {
    return `${API_URL}/adminAgentsFlag/${id}`;
}

export const DEFLAG_URL = (id) => {
    return `${API_URL}/agentsDeflag/${id}`;
}
import React, { Component } from 'react';
import { Trans } from 'react-i18next';

class Footer extends Component {
  render () {
    return (
      <footer className="footer">
        <div className="d-sm-flex justify-content-center justify-content-sm-between">
          <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"><Trans>Copyright © 2022</Trans> <a href="https://www.goldtreeastro.com/" target="_blank" rel="noopener noreferrer">Gold Tree Astro.</a> <Trans>All rights reserved.</Trans></span>
          
        </div>
      </footer>
    );
  }
}

export default Footer;
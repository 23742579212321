import { configureStore } from "@reduxjs/toolkit";

import loginSlice from './login-slice';
import requestSlice from "./request-slice";

const store = configureStore({
    reducer: {login: loginSlice.reducer, request: requestSlice.reducer},
});

export default store;
